import React from "react";

function SingleWorkSkill({ singleArticles }) {
    return (
        <>
            <div className="flex flex-col gap-5">
                <p className="outfit-medium text-violet-500 capitalize text-[1.75rem] md:text-[2.5rem]">
                    Skills
                </p>
                <div className="flex flex-wrap gap-3" data-aos="fade-up">
                    {singleArticles.fields.skills.map((skill, i) => (
                        <p
                            key={i}
                            className="outfit text-[0.9rem] lg:text-[1rem] bg-white/50 backdrop-blur-sm px-4 hover:bg-fuchsia-100 text-slate-900 py-2 rounded-full shadow-sm hover:shadow-none shadow-gray-200 duration-100"
                        >
                            {skill}
                        </p>
                    ))}
                </div>
            </div>
        </>
    );
}

export default SingleWorkSkill;
