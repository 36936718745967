import React from "react";

function SingleWorkStory({ singleArticles }) {
    return (
        <>
            <div className="flex flex-col border-b border-violet-200">
                {singleArticles.fields.professionalJourney.map((pj, i) => (
                    <div
                        key={i}
                        data-aos="fade-up"
                        className="flex flex-col md:flex-row justify-between gap-2 py-14 pr-0 lg:pr-24 xl:pr-32 border-t border-violet-200 w-full"
                    >
                        <p className="outfit-medium text-slate-900 text-[1.5rem]">
                            {pj.fields.title}
                        </p>
                        <p className="outfit text-gray-700 text-[1rem] w-full md:w-2/5">
                            {pj.fields.description}
                        </p>
                    </div>
                ))}
            </div>
        </>
    );
}

export default SingleWorkStory;
