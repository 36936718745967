import React, { useEffect, useState } from "react";
import client from "../../../client";

import ToastMessage from "../../../components/toast/ToastMessage"

function AnotherWork({ anotherWork }) {
    const [articles, setArticles] = useState([]);
    const [error, setError] = useState(null);

    const fetchData = async () => {
        try {
            const response = await client.getEntries({
                content_type: "work",
            });
            const sortedArticles = response.items.sort(
                (a, b) =>
                    new Date(b.fields.startDate) - new Date(a.fields.startDate)
            );
            setArticles(sortedArticles);
        } catch (err) {
            setError(err);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (error) return <div className="flex justify-center"><ToastMessage /></div>;

    return (
        <div className="flex flex-col gap-10">
            <p className="outfit-medium text-violet-500 capitalize text-[1.75rem] md:text-[2.5rem]">
                Other experiences
            </p>
            <div className="flex flex-col gap-8">
                {articles.map((article, i) =>
                    article.fields.slug !== anotherWork ? (
                        <a
                            href={`/work/${article.fields.slug}`}
                            key={i}
                            className="flex flex-col gap-1 hover:text-violet-500 duration-100 w-max"
                        >
                            <p className="outfit-medium text-[1.4rem]">{article.fields.position}</p>
                            <p className="outfit text-gray-500">{article.fields.companyName}</p>
                        </a>
                    ) : null
                )}
            </div>
        </div>
    );
}

export default AnotherWork;
