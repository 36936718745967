import React from "react";

function ToastMessage() {
    return (
        <>
            <div className="animation-fade-up fixed bottom-5 bg-red-100 text-red-600 p-3 m-3 rounded-xl">
                <p className="outfit-semibold">Oops, Something’s Not Right!</p>
                <p className="outfit">
                    We ran into an issue, but no worries—we’ll handle it.
                </p>
            </div>
        </>
    );
}

export default ToastMessage;
