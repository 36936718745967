import React from "react";
import { Link } from "react-router-dom";
import ReactLenis from "lenis/react";
import FloatingBar from "../../components/floating bar/FloatingBar";

import qa from "../../asset/image/Frame 1.webp";
import frontend from "../../asset/image/Frame 2.webp";
import uiux from "../../asset/image/Frame 3.webp";

export default function Project() {
    const projectMenu = [
        {
            name: "Quality Assurance",
            description:
                "Uncover the behind-the-scenes magic of QA that keeps everything running smoothly.",
            link: "qualityassurance",
            image: qa,
        },
        {
            name: "Frontend",
            description: "Take a peek at my Frontend work, check it out!",
            link: "frontend",
            image: frontend,
        },
        {
            name: "UI/UX",
            description:
                "Experience the power of great design, crafted for real-world users.",
            link: "uiux",
            image: uiux,
        },
    ];
    return (
        <ReactLenis root>
            <>
                <FloatingBar />
                <div className="flex flex-col gap-20 pt-32 xs:pt-40 md:pt-44 pb-56 px-3 sm:px-10 lg:px-20">
                    <header
                        data-aos="fade-right"
                        className="flex flex-col gap-2 w-full lg:w-4/5"
                    >
                        <p className="outfit text-[1.25rem] md:text-[1.5rem]">
                            My Projects
                        </p>
                        <h1 className="outfit-semibold text-slate-900 text-[2.5rem] xs:text-[3rem] md:text-[4.75rem] xl:text-[6rem] leading-snug md:leading-[6.5rem]">
                            Where Every Pixel Tells a Story
                        </h1>
                    </header>
                    <section
                        data-aos="fade-right"
                        data-aos-delay="100"
                        className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-10 w-full"
                    >
                        {projectMenu.map((project, index) => (
                            <Link
                                key={index}
                                to={"/" + project.link}
                                className="group flex flex-col items-center gap-5 text-center"
                            >
                                <img
                                    src={project.image}
                                    alt=""
                                    className="group-hover:scale-105 rounded-3xl duration-100"
                                />
                                <div className="flex flex-col gap-1">
                                    <p className="text-slate-900 group-hover:text-violet-600 outfit-medium text-[1.25rem] duration-100">
                                        {project.name}
                                    </p>
                                    <p className="outfit text-gray-600 w-[275px] lg:w-[300px]">
                                        {project.description}
                                    </p>
                                </div>
                            </Link>
                        ))}
                    </section>
                </div>
            </>
        </ReactLenis>
    );
}
