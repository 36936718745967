import React from "react";
import ReactLenis from "lenis/react";
import Profile from "./component/Profile.jsx";

import Work from "./component/Work.jsx";
import Contact from "./component/Contact.jsx";
import SEO from "../../components/seo/SEO.jsx";
import FloatingBar from "../../components/floating bar/FloatingBar.jsx";

const About = () => {
    return (
        <ReactLenis root>
            <SEO title="Cahya Putra Ugira" />
            <FloatingBar />
            <div className="flex flex-col gap-32 pt-32 xs:pt-40 md:pt-44 pb-56 px-3 sm:px-10 lg:px-20 xl:px-44">
                <div className="flex flex-col gap-20">
                    <Profile />
                    <Work />
                </div>
                <Contact />
            </div>
        </ReactLenis>
    );
};

export default About;
