import React, { useEffect, useState } from "react";
import client from "../../../client";
import ToastMessage from "../../../components/toast/ToastMessage";

export default function Contact() {
    const [articles, setArticles] = useState([]);
    const [error, setError] = useState()

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const response = await client.getEntries({
                    content_type: "profile",
                });
                setArticles(response.items);
            } catch (error) {
                setError(error)
            }
        };

        fetchArticles();
    }, []);

    if (error) return <div className="flex justify-center"><ToastMessage /></div>;

    const renderContact = (contact, index) => {
        const isEmail = contact.fields.title === "Email";
        const href = isEmail
            ? `mailto:${contact.fields.hyperlink}`
            : contact.fields.hyperlink;
        const src = `https:${contact.fields.logo.fields.file.url}`;
        return (
            <a href={href} key={index}>
                <img
                    src={src}
                    alt={contact.fields.title}
                    className="w-[40px]"
                />
            </a>
        );
    };

    return (
        <>
            {articles.map((article, i) => (
                <div
                    data-aos="fade-up"
                    data-aos-delay="200"
                    className="flex flex-col gap-5"
                    key={i}
                >
                    <p className="outfit-medium text-[1.5rem] text-slate-900">
                        Let's Get in Touch
                    </p>
                    <div className="flex gap-4">
                        {article.fields.contact.map((contact, i) =>
                            renderContact(contact, i)
                        )}
                    </div>
                </div>
            ))}
        </>
    );
}
