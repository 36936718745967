import React from "react";
import { PaperPlaneRight } from "phosphor-react";

function Index(props) {
    const handleClick = () => {
        window.history.back();
    };

    return (
        <>
            <section className="flex flex-col items-center gap-10 text-center py-56 px-10 tracking-wider">
                <div className="flex flex-col items-center gap-5">
                    <p className="outfit-semibold text-[31px] md:text-[39px] text-black">
                        {props.title}
                    </p>
                    <p className="outfit text-[15px] leading-7 text-black w-full sm:w-3/4">
                        {props.description}
                    </p>
                </div>
                {props.showBackbutton && (
                    <div
                        onClick={handleClick}
                        className="group relative flex items-center justify-center bg-black text-white outfit text-[14px] w-[150px] py-3 rounded-xl cursor-pointer"
                    >
                        <div className="absolute top-1/2 -translate-y-1/2 left-2 group-hover:left-[7.5rem] opacity-0 group-hover:opacity-100 duration-100 ease-out">
                            <PaperPlaneRight size={18} weight="fill" />
                        </div>
                        <span className="group-hover:opacity-0 duration-100">
                            Go back
                        </span>
                    </div>
                )}
            </section>
        </>
    );
}

export default Index;
