import React, { useEffect, useState } from "react";
import client from "../../../client";
import { Link, useNavigate } from "react-router-dom";

import ToastMessage from "../../../components/toast/ToastMessage";

function Work() {
    const [articles, setArticles] = useState([]);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const fetchData = async () => {
        try {
            const response = await client.getEntries({
                content_type: "work",
            });
            const sortedArticles = response.items.sort(
                (a, b) =>
                    new Date(b.fields.startDate) - new Date(a.fields.startDate)
            );
            setArticles(sortedArticles);
        } catch (err) {
            setError(err);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (error) return <div className="flex justify-center"><ToastMessage /></div>;

    return (
        <>
            <div
                data-aos="fade-up"
                data-aos-delay="100"
                className="flex flex-col lg:flex-row gap-4 lg:gap-20"
            >
                {articles.map((article, i) => (
                    <Link
                        to={`/work/${article.fields.slug}`}
                        key={i}
                        className="flex flex-col gap-[2px] text-slate-900 hover:text-violet-600 hover:ml-2 lg:hover:ml-0 rounded-xl duration-100 w-max"
                        onClick={() => {
                            if (!article.fields.slug) {
                                navigate("/404");
                            }
                        }}
                    >
                        <div className="flex items-center gap-2 whitespace-nowrap">
                            <p className="outfit-medium text-[18px]">
                                {article.fields.position}
                            </p>
                            {article.fields.present === true && (
                                <p className="outfit-semibold bg-green-100 px-1 py-[.1rem] text-green-800 text-[0.7rem] uppercase rounded-md">
                                    Current
                                </p>
                            )}
                        </div>
                        <p className="outfit text-gray-600">
                            {article.fields.companyName}
                        </p>
                    </Link>
                ))}
            </div>
        </>
    );
}

export default Work;
