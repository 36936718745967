import React from "react";

const SingleProjectDetails = ({ singleArticles }) => {
    return (
        <div className="flex flex-col gap-10 lg:gap-20 pt-40">
            <section className="flex flex-col items-center gap-20 mx-5 md:mx-32">
                <p className="outfit-medium text-violet-500 capitalize text-[1.5rem]">
                    Story
                </p>
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5 md:gap-10">
                    {singleArticles.fields.componentFrontend.map((pj, i) => (
                        <div
                            key={i}
                            data-aos="fade-up"
                            className="flex flex-col gap-2 w-full xs:w-[350px] bg-fuchsia-100 p-5 rounded-2xl"
                        >
                            <p className="outfit-semibold text-[1rem] xl:text-[1.1rem]">
                                {pj.fields.title}
                            </p>
                            <p className="outfit text-gray-700 text-[0.9rem] lg:text-[1rem]">
                                {pj.fields.description}
                            </p>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    );
};

export default SingleProjectDetails;
