import React from "react";

function Max320() {
    return (
        <>
            <div className="flex flex-col justify-center align-middle gap-2 h-screen text-center tracking-[0.02rem] px-5">
                <p className="outfit-semibold text-slate-900 text-[1.5rem]">
                    Screen Size Too Small
                </p>
                <p className="outfit text-slate-500 leading-6">
                    Your screen size is limiting the view. For the best
                    experience, please use a device with a width of 320px or
                    more.
                </p>
            </div>
        </>
    );
}

export default Max320;
