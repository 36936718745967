import React from 'react'
import Index from './Index';

export default function PageNotFound() {
    return (
        <>
            <Index
                title="404 - Looks like you're lost in space."
                page="404"
                description="Maybe this page doesn't exist or you just spelled something wrong. You can double check the URL if you just spelled something wrong or return to the home."
                showBackbutton={true}/>
        </>
    )
}