import React, { useEffect, useState } from "react";
import client from "../../client";
import { Link } from "react-router-dom";

import FloatingBar from "../../components/floating bar/FloatingBar";
import BadRequest from "../maintenance/400Page";
import SEO from "../../components/seo/SEO";
import ReactLenis from "lenis/react";
import LoadingPage from "../../components/loading/loadingPage";

const sortByDateDescending = (a, b) =>
    new Date(b.fields.date) - new Date(a.fields.date);

function Code() {
    const [articles, setArticles] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await client.getEntries({
                    content_type: "uniqueCode",
                });
                setArticles(response.items.sort(sortByDateDescending));
            } catch (err) {
                setError(err);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, []);

    if (isLoading) return <LoadingPage />;

    if (error) return <BadRequest />;

    return (
        <ReactLenis root>
            <SEO
                title="Being up-to-date with coding and design is essential"
                keywords="Cahya, Cahya Putra, Cahya Putra Ugira, Code, UI Design, Insight, Blog"
                author="Cahya Putra Ugira"
                url="https://rafaelcahya.space/insight"
            />
            <FloatingBar />
            <div className="flex flex-col gap-20 md:gap-32 pt-32 xs:pt-40 md:pt-44 pb-56 px-3 sm:px-10 lg:px-20">
                <div 
                    data-aos="fade-right"className="flex flex-col gap-2 w-full lg:w-5/6">
                    <p className="outfit text-[1.25rem] md:text-[1.5rem]">
                        My Insights
                    </p>
                    <h1 className="outfit-semibold text-slate-900 text-[2.5rem] xs:text-[3rem] md:text-[4.75rem] xl:text-[6rem] leading-snug md:leading-[6.5rem]">
                    Being{" "}
                        <span className="text-transparent bg-clip-text bg-gradient-to-r from-amber-400 to-purple-400">
                            up-to-date
                        </span>{" "}
                        with coding and design is essential
                    </h1>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 mx-auto gap-14">
                    {articles.map((article) => (
                        <Link
                            to={`/insight/${article.fields.slug}`}
                            key={article.sys.id}
                            data-aos="fade-right"
                            className="group flex flex-col gap-2 w-full md:w-[500px] outline-none"
                        >
                            <p className="group-hover:text-violet-500 outfit-medium text-[1.25rem] md:text-[1.75rem] text-slate-900 w-full duration-100">
                                {article.fields.title}
                            </p>
                            <p className="outfit text-[1.1rem] text-slate-600 leading-6 w-full">
                                {article.fields.description}
                            </p>
                        </Link>
                    ))}
                </div>
            </div>
        </ReactLenis>
    );
}

export default Code;
