import React from "react";
import { Link } from "react-router-dom";

export default function FloatingBar() {
    return (
        <div className="fixed left-1/2 -translate-x-1/2 top-10 flex gap-4 bg-white/50 backdrop-blur-xl px-6 rounded-full z-20 w-max">
            <Link
                to="/about"
                className="outfit group p-2.5 rounded-xl duration-100"
            >
                Home
            </Link>
            <Link
                to="/project"
                className="outfit group p-2.5 rounded-xl duration-100"
            >
                Projects
            </Link>
            <Link
                to="/insight"
                className="outfit group p-2.5 rounded-xl duration-100"
            >
                Insights
            </Link>
        </div>
    );
}
