import React, { useEffect, useState } from "react";
import ReactLenis from "lenis/react";
import { useParams } from "react-router-dom";
import client from "../../client";

import FloatingBar from "../../components/floating bar/FloatingBar";
import Markdown from "../../helper/markdown/Markdown";
import AlsoInteristing from "./components/AlsoInteristing";
import BadRequest from "../maintenance/400Page";
import SEO from "../../components/seo/SEO";
import LoadingPage from "../../components/loading/loadingPage";
import PageNotFound from "../maintenance/404Page";

export function withRouter(Children) {
    return (props) => {
        const match = { params: useParams() };
        return <Children {...props} match={match} />;
    };
}

function SingleCode({ match }) {
    const [singleArticles, setSingleArticles] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await client.getEntries({
                    content_type: "uniqueCode",
                    "fields.slug": match?.params.slug,
                });
                setSingleArticles(response.items[0]);
            } catch (err) {
                setError(err);
                setIsLoading(false);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [match?.params.slug]);

    if (isLoading) return <LoadingPage />;

    if (error) return <BadRequest />;

    if (!singleArticles) return <PageNotFound />;

    return (
        <ReactLenis root>
            <SEO
                title={singleArticles.fields.title}
                description={singleArticles.fields.description}
                subject="insight"
                keywords={singleArticles.fields.keywords}
                language={singleArticles.sys.locale}
                revisedDate={singleArticles.sys.updatedAt}
                author="Cahya Putra Ugira"
                url={singleArticles.fields.url}
                newsKeywords={singleArticles.fields.keywords}
            />
            <FloatingBar />
            <div className="flex flex-col gap-20 py-44">
                <div className="flex flex-col items-center gap-10 lg:gap-20 px-3 md:px-10">
                    <h1
                        data-aos="fade-up"
                        className="outfit-semibold text-slate-900 text-[26px] xs:text-[35px] md:text-[39px] xl:text-[55px] text-center tracking-[0.02rem] w-full lg:w-4/5"
                    >
                        {singleArticles.fields.title}
                    </h1>
                    <section className="markdown flex flex-col gap-7 mx-auto outfit text-[1.05rem] text-slate-700 leading-8 w-full lg:w-3/5">
                        <Markdown>{singleArticles.fields.content}</Markdown>
                    </section>
                </div>
                <section className="mx-auto w-full lg:w-3/5 bg-slate-100 border-l-8 border-black">
                    <div className="flex flex-col gap-5 px-3 md:px-10 py-10 max-w-7xl mx-auto">
                        <p className="outfit-semibold text-slate-9000 w-max">
                            Summaries
                        </p>
                        <div className="flex flex-col gap-2 outfit text-slate-700 leading-7 md:leading-8">
                            {singleArticles.fields.summary
                                .slice(0, 2)
                                .map((summary, i) => (
                                    <p key={i}>{summary}</p>
                                ))}
                        </div>
                    </div>
                </section>
                <section className="border-y py-10">
                    <div className="mx-auto w-full lg:w-3/5">
                        <div className="flex flex-wrap justify-center gap-x-3 gap-y-2 outfit-medium text-[12px] rounded-xl">
                            {singleArticles.fields.tags.map((tag, index) => (
                                <p
                                    key={index}
                                    className="uppercase bg-orange-100 text-black py-1 px-2 rounded-md"
                                >
                                    {tag}
                                </p>
                            ))}
                        </div>
                    </div>
                </section>
                <section className="flex flex-col gap-20 px-0 md:px-10">
                    {singleArticles.fields.source ? (
                        <div className="flex flex-col gap-10 mx-5 lg:mx-16 xl:mx-24">
                            <p className="outfit-medium text-violet-500 capitalize text-[1.75rem] md:text-[2.5rem]">
                                Content Origins
                            </p>
                            <div className="flex flex-col gap-5">
                                {singleArticles.fields.source.map((_, i) => (
                                    <a
                                        href={
                                            singleArticles.fields.source[i]
                                                .fields.sourceLink
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                        key={i}
                                        className="group flex flex-col gap-2 cursor-pointer outline-none w-fit"
                                    >
                                        <span className="outfit-medium text-[1.1rem] lg:text-[1.25rem] text-slate-900 group-hover:text-violet-500 duration-100">
                                            {
                                                singleArticles.fields.source[i]
                                                    .fields.sourceTitle
                                            }
                                        </span>
                                        <p className="outfit text-gray-500">
                                            {
                                                singleArticles.fields.source[i]
                                                    .fields.sourceName
                                            }
                                        </p>
                                    </a>
                                ))}
                            </div>
                        </div>
                    ) : null}
                    <AlsoInteristing
                        alsoInteristing={singleArticles.fields.title}
                    />
                </section>
            </div>
        </ReactLenis>
    );
}

export default withRouter(SingleCode);
