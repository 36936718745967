import React from "react";

function SingleWorkGainedExpertise({ singleArticles }) {
    return (
        <>
            <p
                className="outfit text-[1rem] xl:text-[1.1rem] leading-7 xl:leading-8 w-full lg:w-4/5"
                data-aos="fade-up"
            >
                {singleArticles.fields.gainedExpertise}
            </p>
        </>
    );
}

export default SingleWorkGainedExpertise;
